import { HttpHandler, HttpInterceptor, HttpRequest, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { AuthService } from '../../authentication/auth.service';
import { DialogSessionExpiredComponent } from '../../components/dialog/dialog-session-expired/dialog-session-expired.component';
import { HTTPCodes } from '../../enums/http-codes';
import { LOCALSTORAGE_KEYS } from '../../constants/localstorage/localstorage.constants';
import { LocalStorageService } from '../../services/utils/local-storage.service';

const DIALOG_WIDTH = '680px';

@Injectable()
export class ResponseInterceptorProvider implements HttpInterceptor {
  constructor(
    private authServices: AuthService,
    private dialog: MatDialog,
    private localStorage: LocalStorageService
  ) {
      this.localStorage.removeItem(LOCALSTORAGE_KEYS.SESSION_EXPIRED_TOKEN);
  }

  /**
   * @description Intercepts Responses from HTTP Requests
   * @param {HttpRequest<any>} req Outgoing request
   * @param {HttpHandler} next Http event handler
   * @returns {Observable<HttpEvent<any>>} Request handler
   */
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(() => {}, async (err) => {
        if (err.status && (err.status === HTTPCodes.UNAUTHORIZED || err.status === HTTPCodes.FORBIDDEN)) {
          await this.showSessionExpiredAlert();
        }
      })
    );
  }

  /**
   * @description Show session expired alert
   * @returns {Promise<void>}
   */
  public async showSessionExpiredAlert(): Promise<void> {
    const alreadyNotified = this.localStorage.getItem(LOCALSTORAGE_KEYS.SESSION_EXPIRED_TOKEN);

    if (alreadyNotified) {
      return;
    }

    const dialogRef = this.dialog.open(DialogSessionExpiredComponent, {
      disableClose: true,
      width: DIALOG_WIDTH,
    });

    this.localStorage.saveItem(LOCALSTORAGE_KEYS.SESSION_EXPIRED_TOKEN, true);
    await dialogRef.afterClosed().pipe(map(async result => {
      if (result) {
        this.localStorage.removeItem(LOCALSTORAGE_KEYS.SESSION_EXPIRED_TOKEN);
        this.authServices.logout(true);
      }
    })).toPromise();
  }
}
