<div class="background">
  <div class="login-container">
    <div class="logo-container">
      <img
        src="../../../assets/icons/solutions/tep-login.svg"
        class="product-icon"
        alt="tep-icon">
    </div>
    <div class="card-container">
      <mat-card>
        <mat-card-content>
          <form [formGroup]="loginForm" class="login-form">
            <mat-form-field id="user-input-login">
              <input
                matInput
                [placeholder]="labels.usernamePlaceholder"
                formControlName="username">
            </mat-form-field>
            <mat-form-field id="password-input-login">
              <input
                matInput [placeholder]="labels.passwordPlaceholder"
                formControlName="password"
                (keyup.enter)="login()"
                [type]="hide ? 'password' : 'text'">
              <button
                mat-icon-button
                matSuffix
                [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="hide">
                <mat-icon (click)="hide = !hide">
                  {{hide ? 'visibility_off' : 'visibility'}}
                </mat-icon>
              </button>
            </mat-form-field>
          </form>
          <button
            mat-raised-button
            class="login-button"
            (keyup.enter)="login()"
            (click)="login()"
            color="primary"
            [disabled]="isWaitingForResponse || !loginForm.valid">
            {{ labels.loginButton }}
          </button>
          <div class="powered-container">
            <img src="../../../assets/icons/solutions/powered-icon.svg" alt="powered-by-supplynet">
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
