import { Component } from '@angular/core';

import { AppService } from './app.service';

import { ScfCommunicationService, ScfLoaderModule } from 'scf-library';
import { Subscription } from 'rxjs';

const TEP_SOLUTION = 'tep';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  public showLoader: boolean;
  public solution: string;
  public subscription: Subscription;

  constructor(
    private _appService: AppService,
    private scfComService: ScfCommunicationService,
    ) {
      this.solution = TEP_SOLUTION;
      this.subscription = this._appService.getLoaderStatus().subscribe(loaderStatus => {
        if (loaderStatus) {
          this.scfComService.showLoader(loaderStatus);
          this.showLoader = true;
        } else {
          this.scfComService.showLoader(loaderStatus);
          this.showLoader = false;
        }
    });
  }
}
