import { Injectable } from '@angular/core';

@Injectable()
export class LocalStorageService {
    /**
     * @description Save an item into Web browser storage
     * @param itemID {string} Item identificator
     * @param item {any} Item to be saved
     */
    public saveItem(itemID: string, item: any): void {
        localStorage.setItem(itemID, item);
    }

    /**
     * @description Get the specified item by identificator
     * @param itemID {string} Item identificator
     */
    public getItem(itemID: string): any {
        return localStorage.getItem(itemID);
    }

    /**
     * @description Remove the specified item by identificator
     * @param itemID {string} Item identificator
     */
    public removeItem(itemID: string): void {
        localStorage.removeItem(itemID);
    }

    /**
     * @description Parses the object stored in localstorge if there one that matches with the key given
     * @param {string} key Localstorage key to search for
     * @returns { any | undefined} Parsed object
     */
    public getItemAsObject(key: string): any {
        const propertyAsString = localStorage.getItem(key);
        let parsedObject;
        if (propertyAsString) {
            parsedObject = JSON.parse(propertyAsString);
        }
        return parsedObject;
    }
}
