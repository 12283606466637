import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';

import { environment } from '../../../../environments/environment';

import { CookieService } from 'ngx-cookie-service';
import { fromEventPattern } from 'rxjs';
import { NodeEventHandler } from 'rxjs/internal/observable/fromEvent';

import { ApplicationInsightsService } from '../../../monitoring/application-insights/application-insights.service';
import { RoadnetService } from './../../../services/roadnet/roadnet-service';

const AUTHCOOKIE = 'auth';
const PATH = '/biis/logout';
const SESSIONCOOKIE = 'session';
const USERSESSIONCOOKIE = 'userSession';
const LOGOUTIFRAME = 'logout';
const EVENTTYPE = 'load';
const USERLANGUAGECOOKIE = 'language';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss', '../../../app.component.scss']
})
export class LogoutComponent implements OnInit {
  public loggedOut: boolean;
  public url: string = environment.clientUrl + PATH;
  public urlSafe: SafeResourceUrl;

  constructor(
    private applicationInsightsService: ApplicationInsightsService,
    private cookieService: CookieService,
    private roadnetService: RoadnetService,
    private router: Router,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.loggedOut = false;
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);

    const logout = fromEventPattern(
      this.onCompleteLoad
    );

    logout.subscribe(async () => {
      await this.deleteCookies().then(() => {
        this.loggedOut = true;
        this.removeExternalInterfacesConfig();
        this.applicationInsightsService.clearUserId();
        this.router.navigateByUrl('/login');
      });
    });
  }

  /**
   * @description Create EventListener for iFrame
   * @param handler NodeEvent generated from EventPattern
   */
  public onCompleteLoad(handler: NodeEventHandler): void {
    const iframe = document.getElementById(LOGOUTIFRAME);
    iframe.addEventListener(EVENTTYPE, handler);
  }

  /**
   * @description Delete auth, session and userSession cookies
   */
  public async deleteCookies(): Promise<void> {
    this.cookieService.delete(USERSESSIONCOOKIE, '/');
    this.cookieService.delete(AUTHCOOKIE, '/');
    this.cookieService.delete(SESSIONCOOKIE, '/');
    localStorage.removeItem('allowed_routes');
    localStorage.removeItem(USERLANGUAGECOOKIE);
  }

  /**
   * @description Removes all external interfaces stored config
   */
  private removeExternalInterfacesConfig(): void {
    this.roadnetService.removeConfig();
  }
}
