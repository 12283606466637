import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

import { AuthGuard } from './guards/auth.guard';

import { ConfigurationComponent } from './pages/configuration/configuration.component';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { NoPermissionComponent } from './pages/no-permission/no-permission.component';
import { PagesComponent } from './pages.component';

import { AccountsComponent } from './components/iframes/accounts/accounts.component';
import { CarrierRequestComponent } from './components/iframes/carrier-request/carrier-request.component';
import { CatalogsComponent } from './components/iframes/catalogs/catalogs.component';
import { IncidenceCatalogComponent } from './components/iframes/incidence-catalog/incidence-catalog.component';
import { LocationCatalogComponent } from './components/iframes/location-catalog/location-catalog.component';
import { LocationsLoadComponent } from './components/iframes/locations-load/locations-load.component';
import { LogoutComponent } from './components/iframes/logout/logout.component';
import { ManageNotificationsComponent } from './components/iframes/manage-notifications/manage-notifications.component';
import { RequestLoadComponent } from './components/iframes/request-load/request-load.component';
import { RequestLogComponent } from './components/iframes/request-log/request-log.component';
import { SecurityComponent } from './components/iframes/security/security.component';
import { ShipmentEventsComponent } from './components/iframes/shipment-events/shipment-events.component';
import { ShipperConfigurationComponent } from './components/iframes/shipper-configuration/shipper-configuration.component';
import { ShipperRequestComponent } from './pages/shipper-shipment-request/shipper-shipment-request.component';
import { ShippersComponent } from './components/iframes/shippers/shippers.component';
import { TravelHistoryComponent } from './components/iframes/travel-history/travel-history.component';

const appRoutes: Routes = [
  {
    path: '', component: PagesComponent, children: [
      { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
      { path: 'biis/accounts', component: AccountsComponent, canActivate: [AuthGuard] },
      { path: 'biis/carrier-requests', component: CarrierRequestComponent, canActivate: [AuthGuard] },
      { path: 'biis/catalogs', component: CatalogsComponent, canActivate: [AuthGuard] },
      { path: 'biis/incidence-catalog', component: IncidenceCatalogComponent, canActivate: [AuthGuard] },
      { path: 'biis/location-catalog', component: LocationCatalogComponent, canActivate: [AuthGuard] },
      { path: 'biis/location-load', component: LocationsLoadComponent, canActivate: [AuthGuard] },
      { path: 'biis/logout', component: LogoutComponent },
      { path: 'biis/manage-notifications', component: ManageNotificationsComponent, canActivate: [AuthGuard] },
      { path: 'biis/request-load', component: RequestLoadComponent, canActivate: [AuthGuard] },
      { path: 'biis/request-log', component: RequestLogComponent, canActivate: [AuthGuard] },
      { path: 'biis/security', component: SecurityComponent, canActivate: [AuthGuard] },
      { path: 'biis/shipmentEvents', component: ShipmentEventsComponent, canActivate: [AuthGuard] },
      { path: 'biis/shippers', component: ShippersComponent, canActivate: [AuthGuard] },
      { path: 'biis/shipper-configuration', component: ShipperConfigurationComponent, canActivate: [AuthGuard] },
      { path: 'biis/shipper-requests', component: ShipperRequestComponent, canActivate: [AuthGuard] },
      { path: 'biis/travel-history', component: TravelHistoryComponent, canActivate: [AuthGuard] },
      { path: 'catalogs', loadChildren: () => import('./modules/catalogs/catalogs.module').then(m => m.CatalogsModule) },
      { path: 'configuration', component: ConfigurationComponent, canActivate: [AuthGuard] },
      { path: 'cost', loadChildren: () => import('./modules/cost/cost.module').then(m => m.CostModule) },
      { path: 'evidence', loadChildren: () => import('./modules/evidence/evidence.module').then(m => m.EvidenceModule) },
      { path: 'importation', loadChildren: () => import('./modules/importation/importation.module').then(m => m.ImportationModule),
        canActivate: [AuthGuard] },
      { path: 'journey-menu', loadChildren: () => import('./modules/tracking/tracking.module').then(m => m.TrackingModule),
        canActivate: [AuthGuard] },
      { path: 'marketplace', loadChildren: () => import('./modules/marketplace/marketplace.module').then(m => m.MarketplaceModule)},
      { path: 'marketplace-shipper', loadChildren: () => import('./modules/marketplace-shipper/marketplace-shipper.module')
        .then(m => m.ShippersRequestModule)},
      { path: 'massive-movements', loadChildren: () => import('./modules/massive-movements/massive-movements.module')
        .then(m => m.MassiveMovementsModule) },
      { path: 'order', loadChildren: () => import('./modules/order/order.module').then(m => m.OrderModule) },
      { path: 'reports', loadChildren: () => import('./modules/reports/reports.module').then(m => m.ReportsModule)},
      { path: 'roadnet', loadChildren: () => import('./modules/roadnet/roadnet.module').then(m => m.RoadnetModule) },
      { path: 'shipment', loadChildren: () => import('./modules/shipment/shipment.module').then(m => m.ShipmentModule) },
      { path: 'support', loadChildren: () => import('./modules/support/support.module').then(module => module.SupportModule) },
      { path: '', redirectTo: '/home', pathMatch: 'full' }
    ]
  },
  { path: 'login', component: LoginComponent },
  { path: '**', component: NoPermissionComponent }
];

export const routing = RouterModule.forRoot (appRoutes, {
    enableTracing: false,
    preloadingStrategy: PreloadAllModules
});
