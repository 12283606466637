import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { DeleteUserResponse, Profile, User, UserBody, WarehouseChangeBody, WarehouseChanges } from '../../interfaces';
import { environment } from '../../../environments/environment';
import { GridConfig, GridConfigResponse } from '../../interfaces/grid';
import { UserResponse } from '../../interfaces/userResponse.interface';

const apiUrl = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor( private http: HttpClient ) {}

  /**
   * @description Returns the user by id
   * @return User[]
   */
  public async getUserById(userOid: string): Promise<User> {
    return await this.http.get<User>( apiUrl + '/users/' + userOid ).toPromise();
  }

  /**
   * @description Returns system profiles
   * @return Profile[]
   */
  public async getProfile(): Promise<Profile[]> {
    return await this.http.get<Profile[]>( apiUrl + '/perfiles').toPromise();
  }

  /**
   * @description Returns all user emails
   * @return String[]
   */
  public async getEmails(): Promise<Array<string>> {
    return await this.http.get<Array<string>>(apiUrl + '/usuarios?getEmails=1').toPromise();
  }

  /**
   * @description Returns all user usernames
   * @return String[]
   */
  public async getUsernames(): Promise<string[]> {
    return await this.http.get<string[]>(apiUrl + '/usuarios?getUsernames=1').toPromise();
  }

  /**
   * @description Returns all the active users in the system
   * @return User[]
   */
  public async getActiveUsers(): Promise<Array<User>> {
    return await this.http.get<Array<User>>(`${apiUrl}/usuarios?activo=true`).toPromise();
  }

  /**
   * @description Returns all the users with Embarcador profile in the system
   * @return User[]
   */
  public async getShipperUsers(): Promise<Array<User>> {
    return await this.http.get<Array<User>>(`${apiUrl}/usuarios?tipoUsuario=Embarcador Biis`).toPromise();
  }

  /**
   * @description Returns all the users related with shipper in the system
   * @return User[]
   */
  public async getAllShipperUsers(shipperOid: string): Promise<Array<User>> {
    return await this.http.get<Array<User>>(`${apiUrl}/shipper/${shipperOid}/users`).toPromise();
  }

  /**
   * @description Deactivate a user
   * @return DeleteUserResponse
   */
  public async deleteUser(id: string): Promise<DeleteUserResponse> {
    return await this.http.delete<DeleteUserResponse>(`${apiUrl}/users/${id}`).toPromise();
  }

  /**
   * @description Creates user
   * @return CreateUserResponse
   */
  public async createUser(user: UserBody): Promise<Object> {
    return await this.http.post<object>(apiUrl + '/users', user).toPromise();
  }

  /**
   * @description Updates the default warehouse of the User
   * @return UpdateUserResponse
   */
  public async updateUserDefaultWarehouse(userId: string, warehouse: WarehouseChangeBody): Promise<Object> {
    return await this.http.put<Object>(`${apiUrl}/users/${userId}`, warehouse).toPromise();
  }

  /**
   * @description Updates the default warehouse of the User
   * @return UpdateUserResponse
   */
  public async updateUserWarehouseName(shipperId: string, warehouse: WarehouseChanges): Promise<Object> {
    return await this.http.put<Object>(`${apiUrl}/shipper/${shipperId}/users/warehouse`, warehouse).toPromise();
  }

  /**
   * @description Get user by user type
   * @return Array of users searched
   */
  public async getByUsertype(userTypeOid: string): Promise<Array<User>> {
    return await this.http.get<Array<User>>(`${apiUrl}/usuarios?type=${userTypeOid}`).toPromise();
  }

  /**
   * @description Get user by user shipper
   * @return Array of users searched
   */
  public async getUsersByShipper(shipperOid: string): Promise<Array<User>> {
    return await this.http.get<Array<User>>(`${apiUrl}/usuarios?shipper=${shipperOid}`).toPromise();
  }

  /**
   * @description Get user by user shipper
   * @return Array of users searched
   */
  public async getUsersWithoutShipper(): Promise<Array<User>> {
    return await this.http.get<Array<User>>(`${apiUrl}/usuarios?noShipper=true`).toPromise();
  }

  /**
   * @description Update user
   * @return User updated
   */
  public async updateUser(userOid: string, userToUpdate: UserBody): Promise<Array<User>> {
    return await this.http.put<Array<User>>(`${apiUrl}/users/${userOid}`, userToUpdate).toPromise();
  }

  /**
   * @description Get user language by its id
   * @returns {Promise<UserResponse>} Response to Shipper request
   */
  public async getUserLanguage(userOid: string): Promise<UserResponse> {
    return this.http.get<UserResponse>(`${apiUrl}/users/${userOid}/languages`).toPromise();
  }

  /**
   * @description Update user language by its id
   * @returns {Promise<UserResponse>} Response to user request
   */
  public async updateUserLanguage(userOid: string, user): Promise<UserResponse> {
    return this.http.put<UserResponse>(`${apiUrl}/users/${userOid}/languages`, user).toPromise();
  }

  /**
   * @description Save Grid config for the given user.
   * @param {string} tenantOid - Current TenantOid.
   * @param {string} userOid - Current UserOid.
   * @param {GridConfig} gridConfig - Grid config to be saved.
   * @returns {Promise<GridConfig>} Grid config saved.
   */
  public async saveUserGridConfig(tenantOid: string, userOid: string, gridConfig: GridConfig): Promise<GridConfig> {
    return this.http.post<GridConfig>(`${apiUrl}/tenants/${tenantOid}/users/${userOid}/gridConfig`, gridConfig).toPromise();
  }

  /**
   * @description Get Grid configs for the given user.
   * @param {string} tenantOid - Current TenantOid.
   * @param {string} userOid - Current UserOid.
   * @returns {Promise<Array<GridConfig>>} Grid configs.
   */
  public async getGridConfigsByUser(tenantOid: string, userOid: string): Promise<GridConfigResponse> {
    return this.http.get<GridConfigResponse>(`${apiUrl}/tenants/${tenantOid}/users/${userOid}/gridConfig`).toPromise();
  }
}
