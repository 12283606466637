import { HttpHandler, HttpInterceptor, HttpRequest, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppService } from '../../app.service';


@Injectable()
export class TokenInterceptorProvider implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this._appService.getToken();
    if ( typeof token === 'undefined' || !token) {
      return next.handle(req);
    }
      const clone = req.clone({ setHeaders: { 'x-access-token': token, 'token': token } });
      return next.handle(clone);
  }
  constructor(public _appService: AppService) { }
}
