import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';

import { AuthService } from '../../authentication/auth.service';
import { RoadnetService } from './../../services/roadnet/roadnet-service';

import { LoginLabel } from '../../interfaces';
import { loginTags } from './login.labels';

const ROUTE_HOME = '/home';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss', '../../app.component.scss']
})
export class LoginComponent implements OnInit {
  public hide: boolean;
  public labels: LoginLabel;
  public loginForm: UntypedFormGroup;
  public isWaitingForResponse: boolean;

  constructor(private router: Router,
    private authService: AuthService,
    private readonly builder: UntypedFormBuilder,
    private roadnetService: RoadnetService
  ) { this.labels = loginTags; }

  /**
   * @description Angular lifecycle for component initialization.
   */
  public async ngOnInit(): Promise<void> {
    this.isWaitingForResponse = false;
    this.hide = true;
    this.initForm(this.builder);
    await this.validateLoggedInUser();
  }

  /**
   * @description Create body and request to log in system.
   */
  public async login(): Promise<void> {
    this.isWaitingForResponse = true;
    const bodyPetition = {
      username: this.loginForm.value.username,
      password: this.loginForm.value.password
    };
    await this.authService.login(bodyPetition);
    this.setExternalInterfacesConfig();
    this.isWaitingForResponse = this.authService.isRedirectingToSso();

    if (this.isWaitingForResponse) {
      this.initForm(this.builder);
    }
  }

  /**
   * @description Initialize the login form
   * @param fb The login Formbuiler
   */
  private initForm(fb: UntypedFormBuilder): void {
    this.loginForm = fb.group({
      username: new UntypedFormControl(null, [Validators.required]),
      password: new UntypedFormControl(null, [Validators.required]),
    });
  }

  /**
   * @description Validates if the user is authenticated and if the SSO config is active for this user
   * If it's active it redirects the user to SSO login, otherwise redirects to home
   */
  public async validateLoggedInUser(): Promise<void> {
    const isAuthenticated: boolean = this.authService.isAuthenticated();
    const isSsoShipperActive = await this.authService.isUserLoggedShipperSso();

    if (isAuthenticated && !isSsoShipperActive) {
      this.router.navigate([ROUTE_HOME]);
    }
  }

  /**
   * @description Checks wether user interfaces configuration
   */
  private setExternalInterfacesConfig() {
    this.roadnetService.handleRoadnetIntegration();
  }

}
