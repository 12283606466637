import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';

import { AppService } from '../app.service';
import { AuthService } from '../authentication/auth.service';
import { INTERNAL_ROUTES } from './internal-routes';
import { UserService } from '../providers/user/user.service';

const KEY_ALLOWEDROUTES = 'allowed_routes';
const KEY_ITEMS = 'items';
const KEY_ROUTERLINK = 'routerLink';
const ROUTE_HOME = '/home';
const ROUTE_LOGIN = '/login';
const ROUTE_NOPERMISSION = '/no-permission';
const SUPPORT_VIEWS = ['/support/status-changes'];

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  private url: string;

  constructor(
    private appService: AppService,
    private authService: AuthService,
    private router: Router,
    private userProvider: UserService
  ) {}

  /**
   * @description Verify if route to navigate is in allowed routes of the user.
   * @param state The route to navigate
   * @returns {Promise<boolean>} Determinates if route selected can be accessed.
   */
  public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    this.url = state.url;

    return await this.userLogged();
  }

  /**
   * @description Check's if user is logged in, otherwise redirects to login.
   * @return {Promise<boolean>} If user is logged or allowed to access to some views.
   */
  public async userLogged(): Promise<boolean> {
    if (this.authService.isAuthenticated()) {
      if (SUPPORT_VIEWS.includes(this.url) && this.validPath()) {
        return this.isUserAuthorizedForSupportViews();
      } else {
        if (this.validPath()) {
          return true;
        } else {
          return this.showNoPermisionView();
        }
      }
    } else {
      this.router.navigate([ROUTE_LOGIN]);
      return false;
    }
  }

  /**
   * @description Checks if user wants to access to any support view to check user permissions to allow access or deny it.
   * @returns {Promise<boolean>} True if user's config has permissions to allow access to view or deny it.
   */
  public async isUserAuthorizedForSupportViews(): Promise<boolean> {
    const userOid = this.appService.getUserOid();
    const userInfo = await this.userProvider.getUserById(userOid);

    if (userInfo.configuracion?.isSupportFeaturesEnabled) {
      return true;
    } else {
      return this.showNoPermisionView();
    }
  }

  /**
   * @description Displays no permisison view.
   * @returns {boolean} Denies the access to any view.
   */
  public showNoPermisionView(): boolean {
    this.router.navigate([ROUTE_NOPERMISSION]);

    return false;
  }

  /**
   * @description Gets all routes availables in localStorage and validate if current Url is available
   * if homeUrl is not in local, home is added and other paths neccesaries according to modules availables
   */
  public validPath(): boolean {
    let pathExists: boolean;
    const routes = [];
    const allowedModules = JSON.parse(localStorage.getItem(KEY_ALLOWEDROUTES));

    for (const module of allowedModules) {
      if (module.hasOwnProperty(KEY_ROUTERLINK)) {
        routes.push('/' + module.routerLink);
      }
      if (module.hasOwnProperty(KEY_ITEMS)) {
        module.items.forEach(route => routes.push('/' + route.routerLink));
      }
      for (const invisibleRoute of INTERNAL_ROUTES) {
        if (invisibleRoute.module === module.icon) {
          for (const path of invisibleRoute.routes) {
            routes.push(path);
          }
        }
      }
    }

    if (!routes.find((path) => path === ROUTE_HOME)) {
      routes.push(ROUTE_HOME);
    }

    for (const singlePath of routes) {
      if (this.url.startsWith(singlePath)) {
        pathExists = true;
      } else {
        pathExists = false;
      }
      if (pathExists) {
        break;
      }
    }

    return pathExists;
  }
}
